export default [
  {
    question: 'What do you do next?',
    answers: {
      a: 'Proceed with transaction.',
      b: 'Describe benefits of THD app.',
    },
    correctAnswer: 'b',
    responses: {
      a: 'Engage the customer by bringing attention to the Home Depot app and all its benefits.',
      b: 'The app is an easy and convenient way to help you save time, money and stay organized. Plus, it’s free and easy to use.',
    },
  },
  {
    question: 'What do you do next?',
    answers: {
      a: 'Describe membership benefits.',
      b: 'Continue transaction.',
    },
    correctAnswer: 'a',
    responses: {
      a: 'ProXtra is a free loyalty program that allows to you access savings at checkout, redeem offers, and track spending toward earning perks!',
      b: 'Encourage the customer to take advantage of ProXtra perks and benefits.',
    },
  },
  {
    question: 'What do you do next?',
    answers: {
      a: 'Ask the customer if they’d like an e-receipt as well as a printed copy.',
      b: 'Finalize transaction.',
    },
    correctAnswer: 'a',
    responses: {
      a: 'With the THD app, the customer could more easily access purchase history for easy returns/exchanges. For now, if they choose to download it after this purchase, they can scan the printed receipt into their purchase history.',
      b: 'Since this customer doesn’t have the app and is paying in cash, having a receipt is important. Ask if they would like an e-receipt in addition to their printed one.',
    },
  },
  {
    question: 'What do you do next?',
    answers: {
      a: 'Sorry, our new policy will not allow us to return this without a receipt.',
      b: 'Do you have the credit card originally used to purchase this item or are you a ProXtra member?',
    },
    correctAnswer: 'b',
    responses: {
      a: 'Help find another solution for this customer. Ask about the tender type from the transaction.',
      b: '',
    },
  },
  {
    question: 'What do you do next?',
    answers: {
      a: 'Sure thing, I just need your customer ID.',
      b: 'We can do that, but as a reminder, store credit can only be used in-store, not online.',
    },
    correctAnswer: 'b',
    responses: {
      a: 'Remind the customer that store credit has limitations.',
      b: '',
    },
  },
  {
    question: 'What do you do next?',
    answers: {
      a: 'Type in customer name.',
      b: 'Ask to scan customer’s identification',
    },
    correctAnswer: 'b',
    responses: {
      a: 'When possible, “scan if you can” to reduce the chance of user error.',
      b: 'When possible, “scan if you can” to reduce the chance of user error.',
    },
  },
];
