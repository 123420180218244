import Vue from 'vue';
import Vuex from 'vuex';
import question from './modules/question';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: null,
  },
  mutations: {
    setError(state, error) {
      state.error = error;
    },
    clearError(state) {
      state.error = null;
    },
  },
  actions: {
    clearError({ commit }) {
      commit('clearError');
    },
  },
  getters: {
    error: (state) => state.error,
  },
  modules: {
    question,
  },
});
