import questions from '../../data/questions';

export default {
  state: {
    questions,
    currentQuestion: questions[0],
    questionIndex: 0,
    selectedAnswer: '',
  },
  mutations: {
    setCurrentQuestion: (state, payload) => {
      state.currentQuestion = { ...payload };
    },
    setSelectedAnswer: (state, payload) => {
      state.selectedAnswer = payload;
    },
    resetQuestionIndex: (state) => {
      state.questionIndex = 0;
    },
    resetCurrentQuestion: (state) => {
      // eslint-disable-next-line prefer-destructuring
      state.currentQuestion = state.questions[0];
    },
  },
  actions: {
    setSelectedAnswer({ commit }, answer) {
      commit('setSelectedAnswer', answer);
    },
    resetQuestionIndex({ commit }) {
      commit('resetQuestionIndex');
    },
    resetCurrentQuestion({ commit }) {
      commit('resetCurrentQuestion');
    },
    nextQuestion({ commit, state }) {
      try {
        state.questionIndex += 1;
        const nextIndex = state.questionIndex;
        commit('setCurrentQuestion', state.questions[nextIndex]);
      } catch (error) {
        commit('setError', error);
        throw error;
      }
    },
  },
  getters: {
    questions: (state) => state.questions,
    currentQuestion: (state) => state.currentQuestion,
    checkIfLastQuestion: (state) => state.questionIndex === state.questions.length,
    selectedAnswer: (state) => state.selectedAnswer,
  },
};
