<template>
  <transition :name="transitionName">
    <router-view />
  </transition>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    transitionName: '',
  }),
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.transitionName = to.meta.transitionName || from.meta.transitionName;
      next();
    });
  },
};
</script>

<style>
@import "assets/vendor/reset.css";

html {
  font-size: 10px;
}

body {
  height: 100vh;
  overflow: hidden;

  font-family: "Helvetica", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.4;

  background: black;
  color: #ffffff;
}

/* ANIMATION */

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0
}

/* FONTS */

@font-face {
  font-family: Helvetica;
  src: url(assets/fonts/helvetica_regular.otf) format("opentype");
}

@font-face {
  font-family: "Helvetica Bold";
  src: url(assets/fonts/helvetica_bold.otf) format("opentype");
}

/* MEDIA QUERIES */

@media (max-width: 992px) {
  html {
    font-size: 4px;
  }
}

@media (min-width: 992px) and (max-width: 1600px) {
  html {
    font-size: 8px;
  }
}
</style>
