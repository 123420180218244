import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { transitionName: 'fade' },
  },
  {
    path: '/game',
    name: 'Main',
    component: () => import('../views/Main.vue'),
    meta: { transitionName: 'fade' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
